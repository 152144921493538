$lessGutter: .45;
$newGutter: ($grid-gutter-width * $lessGutter) / 2;
.product__content {
    @extend .col-md-4;
    @extend .col-sm-5;
}

.product__swatch__set {
    //margin-left: -$newGutter / 2;
    //margin-right: -$newGutter / 2;
    margin-bottom: $grid-gutter-width;
    h5 {
        //padding-left: $newGutter / 2;
        //padding-right: $newGutter / 2;
        text-transform: uppercase;
    }
    .product__swatches {
        @extend .row;
        margin-left: -$newGutter / 2;
        margin-right: -$newGutter / 2;
        position: relative;
    }
    .product__swatch {
        @extend .col-xs-3;
        width: 20%;
        padding: $newGutter / 2;
        margin-bottom: $newGutter / 4;
        cursor: pointer;
        .color {
            height: 0;
            padding-bottom: 100%;
        }
        img {
            width: 100%;
            border: 1px solid $gray-light;
        }
    }
    .product__swatch__large {
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: none;
        cursor: pointer;
        width: 100%;
        border: 1px solid $gray-light;
        &:first-child {
            display: block;
        }
        img {
            width: 100%;
        }
        .color {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
        .product__swatch__title {
            padding: $newGutter;
            background: white;
            border-top: none;
            position: relative;
            .product__swatch__large__remove {
                .burger {
                    @include burger(25px, 3px, 5px, $brand-secondary);
                    @include burger-to-cross;
                }
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 3;
                display: inline-block;
                color: $brand-secondary;
            }
        }
    }
}
