/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/29c352");


@font-face {font-family: 'Brandon';src: url('../fonts/29C352_3_0.eot');src: url('../fonts/29C352_3_0.eot?#iefix') format('embedded-opentype'),url('../fonts/29C352_3_0.woff') format('woff'),url('../fonts/29C352_3_0.ttf') format('truetype');font-weight: normal;}


@font-face {font-family: 'Brandon';src: url('../fonts/29C352_4_0.eot');src: url('../fonts/29C352_4_0.eot?#iefix') format('embedded-opentype'),url('../fonts/29C352_4_0.woff') format('woff'),url('../fonts/29C352_4_0.ttf') format('truetype');font-weight: lighter;}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Merriweather-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Merriweather-Bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Merriweather-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Merriweather-Bold.svg#bf49ac111abebf0ff978564ba734a014') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  bold;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Merriweather-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Merriweather-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Merriweather-BoldItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Merriweather-BoldItalic.svg#65aeba4904e9f720e9fe01f59a92d9fa') format('svg'); /* Legacy iOS */

  font-style:   italic;
  font-weight:  bold;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Merriweather-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Merriweather-Italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Merriweather-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Merriweather-Italic.svg#6f6738284519f564821d80b0c6d0ed11') format('svg'); /* Legacy iOS */

  font-style:   italic;
  font-weight:  normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Merriweather-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Merriweather-Regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Merriweather-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Merriweather-Regular.svg#f5bc3b56cb236f484d9a38537bb413b1') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  normal;
}

