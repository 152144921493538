@mixin gallery {
    button,
    button:focus,
    button:hover,
    button:active,
    button:active:hover {
        @include center-vertical;
        z-index: 2;
        background: transparent;
        border: none;
        text-indent: -9999px;
        box-shadow: none;
        outline: none;
        opacity: 0;
        padding: 0;
        border-radius: 0;
        @include chevron('left', 25px, 3px, #fff);
        @include grid-media-query(xs) {
            @include chevron('left', 20px, 3px, #fff);
            left: 10px;
        }
        @include grid-media-query(sm) {
            font-size: 3em;
        }
        &.next {
            @include chevron('right', 25px, 3px, #fff);
            right: 0;
            @include grid-media-query(xs) {
                @include chevron('right', 20px, 3px, #fff);
                right: 10px;
                left: auto;
            }
        }
    }
    img {
        max-width: calc(100% - 2px);
    }
    &:hover {
        button,
        button:focus,
        button:hover,
        button:active,
        button:active:hover {
            opacity: 1;
        }
    }
}

.page.page--gallery {
    @include gallery;
}

.product__single {
    .image__title {
        margin-top: .5em;
    }

}

.product__single .gallery .image__container {
    cursor: pointer;
}

.slick-slide {
    outline: none !important;
}

.home__gallery {
    // margin-top: $grid-gutter-width;
    .slick-slide {
        text-align: center;
    }
    img {
       // min-width: 100%;
        max-height: calc(100vh - 260px);
        display: block;
        margin: 0 auto;
    }
}

.page--gallery--list {
    .gallery__container {
        .gallery__image {
            margin-bottom: $grid-gutter-width;
            .gallery__image__container {
                height: 0;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.gallery__modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    top: 0;
    left: 0;
    display: none;
    z-index: 1000;
    .gallery__images {
        @include gallery;
        height: 80vh;
        width: 80vw;
        .slick-slide {
            height: 80vh;
            position: relative;
            img {
                @include center-both;
                z-index: 2;
                max-height: 100%;
                max-width: calc(100% - 2px);
            }
            .glyphicon-refresh-animate {
                -animation: spin .7s infinite linear;
                -webkit-animation: spin2 .7s infinite linear;
                @include center-both;
                z-index: 1;
                color: #fff;
            }
        }
        @include center-both;
    }
    .gallery__modal__close {
        position: fixed;
        top: 40px;
        right: 40px;
        z-index: 100;
        .burger {
            @include burger(25px, 3px, 5px, #fff);
            @include burger-to-cross;
        }
        @include grid-media-query(xs) {
            top: 10px;
            right: 10px;
            padding: 1.8em .5em 0.1em;
            .burger {
                @include burger(25px, 3px, 5px, #fff);
                @include burger-to-cross;
            }
        }
    }
    .slick-loading {
        opacity: 0;
    }
}

.gallery .image__container {
    padding-bottom: 80%;
    height: 0;
    position: relative;
    img {
        @include center-both;
        max-width: 100%;
        max-height: 100%;
    }
}

@-webkit-keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}
