.press__modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    .press__gallery {
        width: 100%;
        max-width: 1000px;
        float: none;
        padding: 0;
        margin: 0 auto;
        margin-top: 2em;
        -webkit-overflow-scrolling: touch;
        .image {
            text-align: center;
            margin-bottom: $grid-gutter-width;
            img {
                //   position: absolute;
                top: 0;
                max-width: 100%;
                max-height: 100vh;
                // @include center-both;
            }
        }
    }
    .press__gallery__close {
        position: fixed;
        top: 40px;
        right: 40px;
        z-index: 100;
        .burger {
            @include burger(25px, 3px, 5px, #fff);
            @include burger-to-cross;
        }
        @include grid-media-query(xs) {
            top: 10px;
            right: 10px;
            padding: 1.8em .5em 0.1em;
            .burger {
                @include burger(25px, 3px, 5px, #fff);
            @include burger-to-cross;
            }
        }
    }
}
