.press {
    .press-category {
        @extend .row;
        margin-bottom: $grid-gutter-width;
        h3,
        h4 {
            @extend .col-md-12;
            text-transform: uppercase;
        }
        > a {
            @extend .col-md-3;
            @extend .col-sm-4;
            @extend .col-xs-6;
            margin-bottom: $grid-gutter-width;
            .image__container {
                height: 0;
                padding-bottom: 130.445859872611%;
                overflow: hidden;
                position: relative;
                img {
                    height: 100%;
                    width: auto;
		position: absolute;
		top:0;
		left: 0;
                }
            }
        }
    }
    h3:first-child {
        margin-top: 0;
    }
    .press__image {
        margin-bottom: $grid-gutter-width;
    }
}
