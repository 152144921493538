main {
	min-height: calc(100vh - 245px);
	@include grid-media-query(xs) {
		min-height: 0;
	}
}
img {
	max-width: 100%;
	height: auto;
}
body, html {
	&.no-scroll {
		overflow-x: hidden;
		overflow-y: hidden;
		height: 100% !important;
	}
}
a:focus {
  border: none !important;
  outline: none !important;
}
h3.page__title {
	text-transform: uppercase;
	margin-top: $grid-gutter-width / 3;
	margin-bottom: $grid-gutter-width / 2;
	@include grid-media-query(xs) {
		margin-bottom: $grid-gutter-width / 2;
	}
	&.product__title {
		font-size: $font-size-h3 - 2;
	}
	a {
			color: $brand-primary;
			&:hover {
				color: $brand-secondary;
			}
		}
	span {
		font-size: 75%;
		a {
			color: $brand-primary;
			&:hover {
				color: $brand-secondary;
			}
		}
	}
}
.tooltip {
	font-family: $font-family-sans-serif;
	text-transform: uppercase;
	@extend .small;
}
.slick-slider .slick-slide{
    display: none;

}
 .slick-slider .slick-slide:first-child{
    display: block;
}
.slick-initialized.slick-slider .slick-slide,
.slick-initialized.slick-slider .slick-slide:first-child{
    display: block;
}
.gallery__container .gallery:not(.slick-initialized) > div {
    display: none;
    &:not(:first-child) {
    	display: block;
    }
}

