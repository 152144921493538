.product .gallery__container {
    @extend .col-md-8;
    @extend .col-sm-7;
    .gallery {
        @include gallery;
        button.next,
        button.next:focus,
        button.next:hover,
        button.next:active,
        button.next:active:hover {
            right: $padding-base-horizontal;
        }
    }
    .gallery__thumbs {
        text-align: center;
        letter-spacing: 8px;
        margin-top: $grid-gutter-width;
        a {
            width: 80px;
            height: 80px;
            display: inline-block;
            position: relative;
            border: 1px solid $gray-light;
            > img {
                @include center-both;
                width: 95%;
            }
            .square {
                @include center-both;
                width: 95%;
                height: 95%;
                overflow: hidden;
                img {
                    @include center-both;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }
    }
}
