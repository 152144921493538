// Glyphicons font path
$icon-font-path:        "../fonts/";



/*

  ____ ___  _     ___  ____  ____
 / ___/ _ \| |   / _ \|  _ \/ ___|
| |  | | | | |  | | | | |_) \___ \
| |__| |_| | |__| |_| |  _ < ___) |
 \____\___/|_____\___/|_| \_\____/

*/

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 66.7%); // #777
$gray-lighter:           lighten($gray-base, 89.5%); // #eee

$brand-primary:		#4c2d2d;
$brand-secondary:	#f4612a;

$text-color:	$brand-primary;
$link-color:	$brand-secondary;

/*
  ____ ____  ___ ____
 / ___|  _ \|_ _|  _ \
| |  _| |_) || || | | |
| |_| |  _ < | || |_| |
 \____|_| \_\___|____/
*/


$siteWidth: 1280px;
$sitePadding: 2.5%;

$screen-sm: 736px;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$link-hover-decoration: none;

//FONTS

$font-family-sans-serif:  "Brandon", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Merriweather", Georgia, "Times New Roman", Times, serif;
$font-family-base:        $font-family-serif;
$font-size-base:          13px;

$line-height-base: 1.61538461538462;

$headings-font-family: $font-family-sans-serif;

$grid-float-breakpoint: 480px;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           500px;
//** Tooltip text color
$tooltip-color:               $gray-base;
//** Tooltip background color
$tooltip-bg:                  $gray-lighter;
$tooltip-opacity:             .9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;



$modal-backdrop-bg:           #000;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .8;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$breakpoints: (xs: $screen-xs, sm: $screen-sm, md: $screen-md, lg: $screen-lg);
