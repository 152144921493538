body.page.home #footer {
    display: none;
}
footer#footer {
    padding-top: 3em;
   // padding-bottom: 15px;
    @extend .row;

    font-family: $font-family-sans-serif;

    @include grid-media-query(lg) {
         font-size: 1.15em;
    }
    .footer__contact {
        @extend .col-md-8;
        padding-top: 14px;
        text-align: left;
        > div {
            display: inline-block;
            vertical-align: bottom;
            margin-right: 15px;
            p {
                margin: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        @include grid-media-query(sm) {
            padding-top: 0;
            text-align: center;
            padding-bottom: 1em;
        }
        @include grid-media-query(md) {
            padding-top: 14px;
            text-align: left;
        }
        @include grid-media-query(xs) {
            text-align: center;
        }
    }
    .footer__right {
        @extend .col-md-4;
        text-align: right;
        @include grid-media-query(sm) {
            padding-top: 0;

        }
        @include grid-media-query(xs) {
            text-align: center;
            padding-top: $grid-gutter-width / 2;
        }
        @include grid-media-query(sm) {
            text-align: center;
            padding-top: 0;
        }
        @include grid-media-query(md) {
            text-align: right;
            padding-top: 0;
        }
        a.footer__newsletter,
        ul.social-icons {
            display: inline-block;
            vertical-align: top;
            @include grid-media-query(xs) {
                display: block;
                margin-bottom: $grid-gutter-width / 2;
            }
            @include grid-media-query(md) {
                vertical-align: bottom;
            }
            @include grid-media-query(sm) {
                vertical-align: bottom;
            }
        }
        a.footer__newsletter {
            text-transform: uppercase;
            margin-right: 1em;
            @include grid-media-query(xs) {
                margin-right: 0;
            }
        }
        ul.social-icons {
            margin: 0;
            list-style: none;
            padding: 0;
            li {
                margin: 0;
                display: inline-block;
                margin-right: .5em;
                &:last-child {
                    margin-right: 0em;
                }
                a {
                    svg {
                        fill: $brand-secondary;
                    }
                    &:hover {
                        svg {
                            fill: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}
