header#header {
	padding-top: 3em;
	padding-bottom: 0.3em;
	margin-bottom: $grid-gutter-width;
	@extend .row;
	@include grid-media-query(sm) {
	}
	@include grid-media-query(xs) {
		padding-top: 2em;
		padding-bottom: 0;
		margin-bottom: $grid-gutter-width / 2;
	}
	@include grid-media-query(md) {
		padding-top: 3.9em;
		padding-bottom: 1em;

	}
	h1, #nav {
		@extend .col-md-6;

	}
	h1 {
		@extend .col-xs-3;
		margin: 0;
		z-index: 100;
		a {
			svg {
				width: 240px;
				fill: $brand-secondary;
				@include grid-media-query(sm) {
					width: 175px;
				}
				@include grid-media-query(xs) {
					width: 175px;
				}
				@include grid-media-query(md) {
					width: 240px;
				}
			}
		}
	}
	#nav {
		@extend .col-xs-9;


		padding-top: 8px;
		.navbar-nav {
			float: right;
			@extend .hidden-xs;
			@include grid-media-query(sm) {
				//float: left;
			}
		}
		li {
			margin-right: 3em;
			&:last-child {
				margin-right: 0;
			}
			&.current-menu-item {
				a {
					color: $brand-secondary;
				}
			}
		}
		li a {
			text-transform: uppercase;
			color: $brand-primary;
			padding: 0;
			font-family: $font-family-sans-serif;
			font-size: $font-size-base + 1;
			&:hover {
				color: $brand-secondary;
				background-color: transparent;
			}
			@include grid-media-query(lg) {
				font-size: $font-size-base + 4;
			}
		}
		.nav__mobile__toggle {
			float: right;
			cursor: pointer;
			@extend .visible-xs;
			padding-left: 20px;
			z-index: 10;
			.burger {
				@include burger(25px, 3px, 5px, $brand-primary);
			}
			&.active {
				.burger {
					@include burger-to-cross;
				}
			}
		}
	}
}
.nav__mobile {
	position: absolute;
	right: -40%;
	background: white;
	top: 80px;
	//padding: 1em 0;
	text-align: left;
	width: calc(30% + 30px);
	height: calc(100vh - 77px);
	@extend .visible-xs;
	@include transition(right,.3s);
	z-index:1000;
	&.active {
		right: 0;
	}
	ul {
		display: block;
		float: none;
		    margin: 7.25px 0;
	}
	li {
		display: block;
		float: none;
	}
	ul li a {
		text-transform: uppercase;
		color: $brand-primary;
		//padding: 0;
		font-family: $font-family-sans-serif;
		@include media("<sm", "landscape") {
			padding: 10px 10px;
		}
	}
}

.nav>li>a:focus, .nav>li>a:hover {
	background: white;
}
