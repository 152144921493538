main.product:not(.product__single) {
	@extend .row;
	> a {
		@extend .col-md-3;
		@extend .col-xs-4;
		text-align: center;
		display: block;
		margin-bottom: $grid-gutter-width * 1.75;
		&:hover {
			color: $brand-secondary;
			text-decoration: none;
			span {
				color: $brand-secondary;
			}
		}
		img {
			display: inline-block;
			width: 100%;
			margin-bottom: .5em;
		}
		span {
			text-transform: uppercase;
			color: $brand-primary;
			font-family: $font-family-sans-serif;
			display: block;
		}
	}
}