.home__slides {
    .home__slide {
        height: calc(100vh - 142px);
        position: relative;
        width: 100vw;
        &.single_image {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
        &.text_image {
            .home__slide__text {
                position: absolute;
                height: 100%;
                width: 50%;
                top: 0;
                left: 0;
                .home__slide__svg {
                    @include center-both;
                    max-height: 30%;
                    width: auto;
                    max-width: 80%;
                    svg {
                        fill: white;
                    }
                }
            }
            .home__slide__images {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 50%;
                .home__slide__image {
                    background-size: cover;
                    background-position: center bottom;
                    background-repeat: no-repeat;
                }
                &.multiple {
                    .home__slide__image {
                        background-position: center center;
                    }
                }
            }
        }
        @include media("<=md") {
            height: calc(100vh - 112px);

        }
        @include media("<=md", "portrait") {
            &.text_image {
                .home__slide__text {
                    bottom: 0;
                    width: 100%;
                    height: 34%;
                    top: auto;
                    .home__slide__svg {
                        max-height: 45%;
                    }
                }
                .home__slide__images {
                    top: 0;
                    width: 100%;
                    height: 66%;
                }
            }
        }
        @include media("<=sm") {
            height: calc(100vh - 80px);

        }
        @include media("<=xs", "portrait") {
            height: calc(100vh - 80px);
        }
        @include media("<=sm", "landscape") {
            height: calc(100vh - 80px);

        }
    }
}
