.page__content, .page__image {
	@extend .col-md-6;
	@extend .col-sm-6;
}
.page, .product {
	h2:first-child {
		margin-top: 0;
	}
  h3:first-child {
    margin-top: 0;
  }

}
main > .page--default {
	//margin-top: $grid-gutter-width;
}
body.page.home, html {
  overflow-x: hidden;

}
body.page.home, html.no-scroll {
    overflow-y: hidden;
}
